import Navbar from "../Navbar";
import Footer from "../Footer";
import EditorHead from "./EditorHead";
import Media from "./control/Media";
import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import queryString from "query-string";

const Editor = ({location}) => {
    const brandName = useSelector(state => state.rebrand.data);
    const currentLayer = useSelector(state => state.currentCampaign.data);
    const id = queryString.parse(location.search).player_type;

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'PlayerReel'} | Editor </title>
            </Helmet>
            <Navbar type={'editor'} />
            <div id="editor-page">
                <div className="container-fluid">
                    <div className="row">
                        <EditorHead id={id}/>
                        <Media currentLayer={currentLayer}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Editor
