import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import logo from "../images/playerReel-logo.png";
import bell_icon from "../images/bell-icon.png";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../actions/authAction";

const Navbar = (props) => {

    console.log(props.type)

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [membership, setMembership] = useState([]);
    const [rebrand, setRebrand] = useState(rebrandData);

    const logout = () => {
        dispatch(logoutUser());
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    useEffect(() => {

        if (auth.user.membership !== '') {
            setMembership(auth.user.membership.split('__'))
        }

    }, [])

    return (
        <>
            <header className={`${props.type===undefined ? 'sticky' : ''}`}>
                <nav id="navbar_top" className={`navbar navbar-expand-lg navbar-light ${props.type===undefined ? 'fixed-me' : ''}`}>
                    <div className="container">

                        <Link className="navbar-brand" to="/dashboard">
                            <img src={rebrand.data !== false ? rebrand.data.logo : logo} alt="PlayerReel Logo"
                                 className="comp-logo"/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                {/* <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                  </li>*/}
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/dashboard"
                                             activeClassName="active">Dashboard</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/my-players">My Players</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/ab-testing">A/B Test</NavLink>
                                </li>
                                {
                                    auth.user.is_client_account === "0" ?
                                        <>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/integration">Integrations</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                {/*
                                <NavLink className="nav-link" to="/analytics">Analytics</NavLink>
*/}
                                                <div className="btn-group user-row">
                                                    
                                                    <a className="nav-link dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false">
                                                        Stats
                                                    </a>
                                                    
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <li>
                                                            <Link className="dropdown-item" to="/analytics">
                                                                Analytics
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="/engagement">
                                                                Engagement
                                                            </Link>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <a className="dropdown-item" href= "#">*/}
                                                        {/*        A & B Split Stats*/}
                                                        {/*    </a>*/}
                                                        {/*</li>*/}
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                        : ''
                                }

                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/help-and-support">Help</NavLink>
                                </li>
                            </ul>

                            <div className="btn-group user-row">
                                <button type="button" className="btn btn-user dropdown-toggle" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                            <span id="prof_icon">
                            <img src={auth.user?.image} alt="User Image"/>
                            </span>
                                    <div className="btn-details">
                                        <h4 className="welcome">Welcome</h4>
                                        <p className="btn-user-name">{auth.user?.name}</p>
                                    </div>
                                </button>
                                <ul className="dropdown-menu" id="menu-show">
                                    {
                                        auth.user.parent_id === null ?
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/profile">
                                                        <span className="mr-2"><i className="fas fa-user"></i></span> My
                                                        Account
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="/privacy">
                                                        <span className="mr-2"><i
                                                            className="fas fa-key"></i></span> Privacy Setting
                                                    </Link>
                                                </li>
                                            </>
                                            : ''
                                    }

                                    {
                                        auth.user.is_client_account === "0" ?
                                            <li>
                                                <Link className="dropdown-item" to="account-management">
                                                        <span className="mr-2">
                                                            <i className="fas fa-users"
                                                               style={{fontSize: "14px"}}></i>
                                                        </span>
                                                    Account Management
                                                </Link>
                                            </li>
                                        : ''
                                    }

                                    {
                                        auth.user.is_client_account === "0" ?
                                            <>
                                                <li>
                                                    <Link className="dropdown-item" to="/upgrades">
                                                        <span className="mr-2"><i
                                                            className="fas fa-plus-square"></i></span> Upgrades
                                                    </Link>

                                                </li>
                                            </>
                                            : ''
                                    }
                                    <li>

                                        <Link className="dropdown-item" to="/help-and-support">
                                            <span className="mr-2"><i className="fas fa-headset"></i></span> Help &
                                            Support
                                        </Link>

                                    </li>
                                    <li>
                                        <Link className="dropdown-item" onClick={(e) => logout()}>
                                        <span className="mr-2">
                                            <i className="fas fa-sign-out-alt"></i>
                                        </span> Sign Out
                                        </Link>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}


export default React.memo(Navbar);
