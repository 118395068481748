import play_icon from "../../images/play-icon.png";
import player_icon from "../../images/player-icon.png";
import next_icon from "../../images/next.png";
import playlist_icon from "../../images/playlist-icon.png";
import finder_icon from "../../images/finder-icon.png";
import React, {useState,useEffect} from "react";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import closeModalx from "../../images/closeModal.svg"

const DashboardHead = ()=>{
    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);
    const [frameUrl, setFrameUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [bgVideo, setBgVideo] = useState(false);
    const [demoVideo, setDemoVideo] = useState('');


    const addFrameUrl = () => {
        setShowModal(true);
        setFrameUrl(demoVideo);
    }

    const closePopUp = () => {
        setFrameUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    useEffect(()=>{

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/api/walkthrough',
            data: {id: 38},
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                setBgVideo(res.data.data[0].bg_video);
                setDemoVideo(res.data.data[0].demo_video);
            }
        }).catch(err=>{

        });

    },[]);

    return(
        <>
            <section id="banner">
                <div id="bg-video">
                    {/* <iframe
                        src={`${bgVideo ? bgVideo+'?background=1&autoplay=1&loop=1&byline=0&title=0' : ''}`}
                        frameBorder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allowFullScreen
                        title="course"
                    ></iframe> */}
                    <video
                       src="https://reeelapps-app.s3.us-west-2.amazonaws.com/playerreel_dashboard_bg_video+(1080p).mp4"
                        autoPlay
                        muted
                       style={{ width: "100%" }}
                       loop
                    />
                   
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <p className="banner-subtitle">WELCOME TO</p>
                            {
                                rebrand.data === false ? <h1 className="banner-title"><span>PLAYER</span>REEL</h1> : <h1 className="banner-title">{rebrand.data.name}</h1>
                            }
                            <p className="banner-description">
                                Turn any video or video URL into a beautifully customized video player in seconds. Watch {
                                rebrand.data === false ? <span>PlayerReel</span> : <span>{rebrand.data.name}</span>
                            } instantly create a unique, traffic-getting videos player that you and your clients will love.
                            </p>
                            <button type="button" className="gradiant-button-pb watch-btn"  onClick={(e) => addFrameUrl()} data-toggle="modal" data-target="#myModal">
                                <img src={play_icon} alt="Play icon" className="play-icon"/>
                                Watch Demo Video
                            </button>
                        </div>
                        {
                            auth.user.is_client_account === "0" ?
                                <div className="col-md-12 col-lg-6">
                                    <div className="more-about-services">
                                        <div className="more-about-services-list mb-3">
                                            <Link to="/create-player" className="btn btn-gradient-one btn-st">
                                                <div className="serv-box">
                                                    <div className="serv-icon">
                                                        <img src={player_icon} alt="player icon"/>
                                                    </div>
                                                    <p>Create Player</p>
                                                </div>
                                                <div className="next1">
                                                    <img src={next_icon} alt="Right arrow" className="right-arrow"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="more-about-services-list mb-3">
                                            <Link to="/analytics" className="btn btn-gradient-two btn-st">
                                                <div className="serv-box">
                                                    <div className="serv-icon">
                                                        <img src={playlist_icon} alt="player icon"/>
                                                    </div>
                                                    <p>Analytics</p>
                                                </div>
                                                <div className="next1">
                                                    <img src={next_icon} alt="Right arrow" className="right-arrow"/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="more-about-services-list mb-3">
                                            <Link to="/video-finder" className="btn btn-gradient-three btn-st">
                                                <div className="serv-box">
                                                    <div className="serv-icon">
                                                        <img src={finder_icon} alt="player icon"/>
                                                    </div>
                                                    <p>Video Finder</p>
                                                </div>
                                                <div className="next1">
                                                    <img src={next_icon} alt="Right arrow" className="right-arrow"/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            : ''
                        }
                    </div>
                </div>
            </section>
            <div className={`commonModal modal ${showModal ? 'show-modal' : ''} `}>
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">

                                        
                                            
                                            <button onClick={(e) => closePopUp('')} type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">
                                                       <img src={closeModalx} />
                                                    </span>
                                            </button>
                                     

                                        <div className="modal-body">
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe id="frameSrc" className="embed-responsive-item" src={frameUrl} allowFullScreen=""/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
            </>
    )
}
export default DashboardHead;
