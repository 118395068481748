import join_now from "../../images/join-now-img.png";
import play_icon from "../../images/play-icon.png";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";

const Webinar = ()=>{

    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [webinar, setWebinar] = useState({
        title: '',
        description: '',
        btn_link: '',
        btn_text: '',
        status: '1'
    });

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    useEffect(()=>{

        axios({
            method: 'POST',
            url: 'https://adminserver.reelapps.io/api/webinar',
            data: {id: 38},
            headers: {'Content-Type' : 'application/json'}
        }).then(res=>{
            if(res.data.status === true){
                setWebinar(res.data.data[0]);
            }
        }).catch(err=>{

        });

    },[]);

    return(
        <>
            <section id="join-now">
                <div className="container">
                    <div className="row revert">
                        <div className="col-12 col-md-6 f-right">
                            <div className="join-now-img">
                                <img src={join_now} alt="join now"/>
                            </div>
                        </div>
                        {
                            webinar.btn_link !== '' ?
                                <div className="col-12 col-md-6 f-left">
                                    <div className="pt-sm-3">
                                        <h5 className="subtitle text-white">
                                            {
                                                rebrand.data === false ? <span>PlayerReel</span> : <span>{rebrand.data.name}</span>
                                            }
                                            {" "} LESSON #1
                                        </h5>
                                        <h2 className="text-medium">{webinar.title}</h2>
                                        <p className="mt-4">
                                            {webinar.description}
                                        </p>

                                        <a href={webinar.btn_link} target="_blank">
                                            <button type="button" className="gradiant-button-pb watch-btn mt-4">
                                                {webinar.btn_text}
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            : ''
                        }
                    </div>
                </div>
            </section>
            </>
    )
}

export default Webinar;
