import reelApps from "../images/reelApps.png";
import logo from "../images/playerReel-logo.png";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const Footer = () => {

    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);


    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData]);

    return (
        <>
            <footer className="footer-mid-light">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-4">
                            <ul className="footer-menu" style={{fontSize: "15px"}}>
                                <li>
                                    <a href="https://reelapps.io/terms.html" target="_blank">Terms</a>
                                </li>
                                <li>
                                    <a href="https://reelapps.io/privacy.html" target="_blank">Privacy</a>
                                </li>
                                <li>
                                    <a href="https://vineasx.helpscoutdocs.com/" target="_blank">Support</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-sm-12 col-md-5">
                            <p className="copyright" style={{fontSize: "15px"}}>© {(new Date().getFullYear())}, All Rights Reserved to VineaSX Solutions LLC. Developed and Maintained by
                                    <a href="https://vega6.com/" target="_blank" className="ml-2">Vega6</a>
                            </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                            <div className="reelapps-img">
                               {/* <a href="https://reelapps.io/" target="_blank">
                                    <img src={rebrand.data !== false ? rebrand.data.logo : reelApps}/></a>*/}
                                {
                                 rebrand.data !==false ? ( <a>
                                     <img src={ rebrand.data.logo }/></a> ) : (
                                     <a href="https://reelapps.io/" target="_blank">
                                         <img src={ reelApps}/></a>
                                 )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
